/** Sending timing data to Google Analytics
 *
 * This must be loaded as early as possible (before any references to sendTimingData() in our page HTML
 */

// Metrics defined on page by page basis
var blue_green_metrics = {
    'showthread': ['nav', 'title', 'sidebar']
};
var visually_complete_metrics = {
    'showthread': ['nav', 'title', 'sidebar', 'ads']
};

// Determine whether user should send timing data based on admincp sample size setting
var shouldSendTimingData = false;
var sampleSeed = Math.floor(Math.random() * (100) + 1);
if ( typeof tsr.data.timingdata == 'undefined' ) {
    tsr.data.timingdata = {
        samplepercentage: 0,
        markers: ''
    };
}
var samplePercentage = tsr.data.timingdata.samplepercentage;
if ( typeof samplePercentage == 'undefined' ) {
    samplePercentage = 0;
}
if ( sampleSeed < samplePercentage+1 ) {
    shouldSendTimingData = true;
}

// Admincp setting for what timing marks should be sent
if ( typeof tsr.data.timingdata.markers == 'undefined' ) {
    tsr.data.timingdata.markers = '';
}
var markers = tsr.data.timingdata.markers.split(',');

/**
 * Function to calculate various timing marks for sending off to Google Analytics
 *
 * @param {string} timing_name
 * @param {number} override_time
 *
 * @return void
 */
function sendTimingData(timing_name, override_time) {

    // Only run if within the sample percentage
    if ( !shouldSendTimingData ) {
        return;
    }

    // Only run for showthread pages
    if ( THIS_SCRIPT !== 'showthread' ) {
        return;
    }

    // Need window.performance to calculate timings
    if (!window.performance) {
        return;
    }

    // Calculate time since page load (use override time if one is passed in)
    var timeSincePageLoad = (typeof override_time === 'undefined') ? Math.round(performance.now()) : override_time;

    var marker_name = 0;

    var timing_marks = ['startrender', 'fullyloaded'];
    var blue_green_index;
    var visually_complete_index;

    if ( (timingMark = timing_marks.indexOf(timing_name)) !== -1 ) {
        // SEND TIMING MARK
        timing_marks.splice(timingMark, 1);
        sendTimingDataToGA(timing_name, timeSincePageLoad);
    } else {
        // Remove marker from visually complete list
        if ( (visually_complete_index = visually_complete_metrics[THIS_SCRIPT].indexOf(timing_name)) !== -1 ) {
            visually_complete_metrics[THIS_SCRIPT].splice(visually_complete_index, 1);
            
            // Send blue and green metric if no more markers left
            if ( visually_complete_metrics[THIS_SCRIPT].length === 0 ) {
                marker_name = 'visuallycomplete';
                sendTimingDataToGA(marker_name, timeSincePageLoad);
            }
        }        

        // Remove marker from blue and green list
        if ( (blue_green_index = blue_green_metrics[THIS_SCRIPT].indexOf(timing_name)) !== -1 ) {
            blue_green_metrics[THIS_SCRIPT].splice(blue_green_index, 1);
            
            // Send visually complete metric if no more markers left
            if ( blue_green_metrics[THIS_SCRIPT].length === 0 ) {
                marker_name = 'blueandgreen';
                sendTimingDataToGA(marker_name, timeSincePageLoad);
            }
        }
    }
};

/**
 * Function to send timing data to Google Analytics
 *
 * @param {string} timing_name
 * @param {number} time
 *
 * @return void
 */
function sendTimingDataToGA(timing_name, time){
    // Don't send to GA if not switched on in admincp
    if ( markers.indexOf(timing_name) === -1 ) {
        return;
    }

    console.log('TIMING MARK - ' + timing_name + ' - ' + time);

    // Send timing data to GA
    ga('send', 'timing', timing_name, 'load', time);
};

// Once all resources originally part of the DOM have loaded (images, stylesheets, etc.)
function fullyLoaded(){
    sendTimingData('fullyloaded');
};

// Add event listener instead of using window.onload as that gets overwritten...
window.addEventListener ? window.addEventListener("load",fullyLoaded,false) : window.attachEvent && window.attachEvent("onload",fullyLoaded);
