/*
 * Taken from templates/facebook/sdk.html
 */
 if (tsr.data.structure.get('facebook_sdk_active')) {
     window.fbAsyncInit = function() {
         FB.init({
             appId: '{vb:raw vboptions.fb_appid}',
             cookie: true,
             status: true,
             version: 'v9.0',
             xfbml: true
        });
        FB.Event.subscribe('edge.create', function () {
            _gaq.push(['_trackPageview', '/virtual/outgoing/fblike']);
        });
        FB.Event.subscribe('message.send', function(targetUrl) {
            _gaq.push(['_trackSocial', 'facebook', 'send', targetUrl]);
        });
     };
     (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
         js = d.createElement(s); js.id = id;
         js.src = "//connect.facebook.net/en_GB/sdk.js";
         fjs.parentNode.insertBefore(js, fjs);
     }(document, 'script', 'facebook-jssdk'));
 }


/*
 * Taken from supplements/clientscript/extra/tsr_twitter.js
 */

 if (tsr.data.structure.get('twitter_sdk_active')) {
     window.twttr = (function (d,s,id) {
         var t, js, fjs = d.getElementsByTagName(s)[0];
         if (d.getElementById(id)) return; js=d.createElement(s); js.id=id;
         js.src="//platform.twitter.com/widgets.js";
         fjs.parentNode.insertBefore(js, fjs);
         return window.twttr || (t = { _e: [], ready: function(f){ t._e.push(f) } });
     }(document, "script", "twitter-wjs"));

     twttr.ready(function (twttr) {

     twttr.events.bind('tweet', trackTwitter);

         twttr.events.bind('loaded', function (event) {
             if ($('.tweet').length > 0) {
                $('.tweet').show();
             }
             if ($('.tweet-block').length > 0) {
                $('.tweet-block').css("display", "inline-block");
             }
         });
     });

     function trackTwitter(intent_event) {
        if (intent_event) {
            var opt_pagePath;                                                                                                                                                                                                                           if (intent_event.target && intent_event.target.nodeName == 'IFRAME') {                                                                                                                                                                            opt_target = extractParamFromUri(intent_event.target.src, 'url');                                                                                                                                                                     }
            _gaq.push(['_trackSocial', 'twitter', 'tweet', opt_target]);
        }
     }

     function extractParamFromUri(uri, paramName) {
        if (!uri) {
            return;
        }
        var regex = new RegExp('[\\?&#]' + paramName + '=([^&#]*)');
        var params = regex.exec(uri);
        if (params != null) {
            return unescape(params[1]);
        }
        return;
     }
 }

/**
 * Creates a form, adds parameters to it and submits it in the parent window
 * 
 * @param array params - Array of parameters to add to our form in the format {name: '', value: ''}
 */
function createAndSubmitGoogleSigninForm(params) {
    var f = parent.document.createElement('form');

    var parentUrl = params.find(function (param) {
        return param.name == 'parent_url';
    });

    f.action='/googlesignin.php?do=login' + (parentUrl ? '&parent_url=' + encodeURIComponent(parentUrl.value) : '');
    f.method='POST';

    for (var param in params) {
        var i=document.createElement('input');
        i.type='hidden';
        i.name=params[param].name;
        i.value=params[param].value;
        f.appendChild(i);
    }

    parent.document.body.appendChild(f);
    f.submit();
}

/**
 * Handle successful sign-ins from google
 * 
 * @param object response - Response from googles sign-in window
 */
function handleGoogleSignin(response) {
    var credential = response.credential;
    var login_source = response.select_by;

    // get parameters
    if (typeof URLSearchParams === 'function') {
        var urlParams = new URLSearchParams(window.location.search);
    }
    
    var form = $('form.register-form');
    if (!form.is(':visible')) {
        form = $('form.signin-form');
    }

    var tsrl_data = '';
    if (typeof urlParams.get('tsrl_data') !== 'undefined' && urlParams.get('tsrl_data') != null && urlParams.get('tsrl_data') !== '') {
        tsrl_data = urlParams.get('tsrl_data');
    }

    // One-tap or no form on the page - need to create a dummy form and add some elements to it
    if (login_source.startsWith('user') || login_source.startsWith('itp') || !form) {
        var parentUrl = getParameter('parent_url');
        if (!parentUrl) {
            parentUrl = window.location.pathname + window.location.search;
        }
        var params = [
            {name: 'credential', value: credential}, 
            {name: 'login_source', value: login_source}, 
            {name: 'url', value: parentUrl},
            {name: 'parent_url', value: parentUrl}
        ]
        if (tsrl_data) {
            params.push({name: 'tsrl_data', value: tsrl_data});
        }
        if (login_source.startsWith('user') || login_source.startsWith('itp') ) {
            params.push({name: 'route', value: 'google-one-tap'});
        }

        createAndSubmitGoogleSigninForm(params);
    } else {
        // Get our list of fields
        var params = form.serializeArray();
        params.push({name: 'credential', value: credential}, {name: 'login_source', value: login_source});
        if (tsrl_data) {
            params.push({name: 'tsrl_data', value: tsrl_data});
        }

        createAndSubmitGoogleSigninForm(params);        
    }
}
