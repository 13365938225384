/**
 * Bits and bobs of JavaScript that for one reason or another need to be loaded in the <head> tag
 *
 * This is a headsync file, so overhead needs to be kept to a minimum.
 */

// GA
if (typeof _gaq !== 'object' || Object.prototype.toString.call(_gaq) !== '[object Array]') {
    _gaq = [['_setAccount', tsr.data.structure.get('ga_profileid')],['_setDomainName', tsr.data.structure.get('ga_domain')], ['_setSiteSpeedSampleRate', 10]];
} else {
    _gaq.push(['_setAccount', tsr.data.structure.get('ga_profileid')]);
    _gaq.push(['_setDomainName', tsr.data.structure.get('ga_domain')]);
    _gaq.push(['_setSiteSpeedSampleRate', 10]);
}

// Dataxu
if (tsr.data.structure.get('dataxu_active')) {
    (function() {
        var script = document.createElement('script');
        script.src = "https://cti.w55c.net/ct/dx_upx_0DHo12Rmap.js";
        script.text = "sorttable.init()";
        document.getElementsByTagName('head')[0].appendChild(script);
    })();
}

// Decibel Insight
if (tsr.data.structure.get('decibel_active')) {
    (function(d,e,c,i,b,el,it) {
        d.da=d.da||[];da.oldErr=d.onerror;da.err=[];
        d.onerror=function(){da.err.push(arguments);da.oldErr&&da.oldErr.apply(d,Array.prototype.slice.call(arguments));};
        d.DecibelInsight=b;d[b]=d[b]||function() {(d[b].q=d[b].q||[]).push(arguments);}
        ;
        el=e.createElement(c),it=e.getElementsByTagName(c)[0];el.async=1;el.src=i;it.parentNode.insertBefore(el,it);
    })(window,document,'script','//cdn.decibelinsight.net/i/45272/di.js','decibelInsight');

    (function() {
        var goals = tsr.data.structure.get('decibel_goals');
        for (var i = 0; i < goals.length; ++i) {
            decibelInsight("sendGoal", goals[i]);
        }
    })();
}

// Optimizely template (legacy variable)
var firstViewTime = null;


var pageLoadtime = null;

// SVG FIX
if (/(applewebkit|chrome)/.test(navigator.userAgent.toLowerCase())) {
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = TSR_CSS_PATH + '/cpstyles/tsr/extra/svgfix' + (!IS_DEV ? '.min' : '') + '.css';
    document.getElementsByTagName('head')[0].appendChild(link);
}

// JS Console
if (!tsr.data.structure.get('js_console_active')) {
    (function() {
        var off = function() {/**/};
        window.console = {
            log: off,
            debug: off,
            info: off,
            warn: off,
            error: off,
            assert: off,
            dir: off,
            dirxml: off,
            group: off,
            groupEnd: off,
            time: off,
            timeEnd: off,
            count: off,
            trace: off,
            profile: off,
            profileEnd: off
        };
    })();
}

// Android class name
if (navigator.userAgent.match(/Android/i)) document.documentElement.className += ' android';

(function() {
	var html = document.getElementsByTagName('html')[0];
	html.className = html.className.replace(/\bnojs\b/, '');
})();

var UADimension6;
var UADimension7;
var totalPageViewCookie = COOKIE_PREFIX + '_tpv';
var pageViewBeforePostingCookie = COOKIE_PREFIX + '_pvbp';

// check to see if total pageview cookie exists
if (UADimension6 = parseInt(fetch_cookie(totalPageViewCookie))) {
    UADimension6++;
} else {
    UADimension6 = 1;
}

// check to see if pageview before post cookie exists
if (UADimension7 = parseInt(fetch_cookie(pageViewBeforePostingCookie))) {
    UADimension7++;
} else {
    UADimension7 = 1;
}

// Dont count DPC pages as a pageview
if (window.location.href.indexOf("/dpc/") === -1) {
    document.cookie = totalPageViewCookie + '=' + UADimension6 + '; path=/; secure=true';
    document.cookie = pageViewBeforePostingCookie + '=' + UADimension7 + '; path=/; secure=true';
}
