/**
 * Check if the mobile style is currently in effect
 *
 * @return bool
 */
function isMobile() {
    return $(window).width() <= 480;
    return getComputedStyle(document.body, ':after').getPropertyValue('content').indexOf('480px') != -1;
}

// Refactored version of function from vbulletin-core.js
var fetch_all_cookies = function() {
    var ca = document.cookie.split(';');
    var cookies = [];
    for (var i in ca) {
        while (ca[i].charAt(0) == ' ') ca[i] = ca[i].substring(1, ca[i].length);
        var ci = ca[i].split('=');
        cookies[ci[0]] = ci[1];
    }
    return cookies;
}
var global_cookies = fetch_all_cookies();
var fetch_cookie = function(name) {
    global_cookies = fetch_all_cookies();
    if ( typeof(global_cookies[name]) == "undefined" ) return null;
    return global_cookies[name];
};
var delete_cookie = function(name) {
    document.cookie = name + "=; expires=Thu, 01-Jan-70 00:00:01 GMT; path=/"
};

var set_cookie = function(_strCookieName, _strCookieValue, _strOptionalExpires) {
    document.cookie = _strCookieName + "=" + encodeURIComponent(_strCookieValue) + "; path=/" + (typeof _strOptionalExpires != "undefined" ? "; expires=" + _strOptionalExpires.toGMTString() : "");
};

/**
 * Get user behavioural targeting parameter (u_b_f)
 *
 * @return string
 */
function getUserBehave() {
    var ff;
    try {
        ff = JSON.parse(fetch_cookie('tsr_behave_ff'));
    } catch (e) {}
    if (!ff) ff = [];
    var d = fetch_cookie('tsr_behave_b') || '';
    return ff.join(' ') + (d.length ? (ff.length ? ' ' : '') + d : '');
}

/**
 * Get the Krux parameter specified
 *
 * @return string
 */
function getKruxData(n) {
    var k = 'kx'+ n;
    if ('localStorage' in window && !!window.localStorage) return window.localStorage[k] || '';
    if (navigator.cookieEnabled) {
        var m = document.cookie.match(k+'=([^;]*)');
        return (m && unescape(m[1])) || '';
    }
    return '';
}

/**
 * Track event in Google Analytics (GA & UA)
 * @param string name
 * @param string type
 * @param string data
 */
function trackEvent(name, type, data) {
    // We don't load GA on pages with new structure, only UA
    if ( typeof _gaq !== 'undefined' ) {
        _gaq.push(['_trackEvent', name, type, data]);
    }
    ga('send', 'event', name, type, data);
}

/*
 * Track event using a JSON in Google Analytics (GA & UA)
 * @param string name
 * @param string type
 * @param string data
 */
function trackEventData(data) {

    // We don't load GA on pages with new structure, only UA
    if ( typeof _gaq !== 'undefined' ) {
        _gaq.push(['_trackEvent', data]);
    }
    ga("send", data);
}

/**
 * Send a page view to Google Analytics
 *
 * @param {string} uri - uri to be tracked in UA
 * @param {string/bool} action - indicating action, e.g. click, hover.  false if no action
 */
function trackPageView(uri, action) {

    if ( action ) {
        if ( uri.indexOf('?') >= 0 ) {
            uri += '&';
        } else {
            uri += '?';
        }
        uri += 'action=' + action;
    }

    if (uri && uri.length > 0) {
        ga('send', 'pageview', uri);

        // return what got tracked for testing.
        return uri;
    }

    // nothing happend for testing.
    return "";
}

/**
 *
 * Display sliding error message from top of screen
 *
 * @param msg String optional - error to display
 */
function displayError(msg, atTop) {
    if ( typeof msg === 'undefined' ) {
        msg = 'Something went wrong, please try again!';
    }
    var notice = $('#noticeSlider');
    if (typeof atTop === 'undefined') {
        notice.addClass('error').removeClass('at-top-edge').removeClass('bottom-notice').removeClass('success').removeClass('notice').removeClass('hidden');
    } else {
        notice.addClass('error').addClass('at-top-edge').removeClass('bottom-notice').removeClass('success').removeClass('notice').removeClass('hidden');
    }
    notice.children('div.notice-content').html(msg);
    hideErrorSuccessNotice(false);
}

/**
 *
 * Display sliding success message from top of screen
 *
 * @param msg String - success message to display
 */
function displaySuccess(msg) {
    if ( typeof msg === 'undefined' ) return;
    var notice = $('#noticeSlider');
    notice.addClass('success').removeClass('at-top-edge').removeClass('bottom-notice').removeClass('error').removeClass('notice').removeClass('hidden');
    notice.children('div.notice-content').html(msg);
    hideErrorSuccessNotice(false);
}

/**
 *
 * Display sliding notice message from top of screen
 *
 * @param msg String - notice message to display
 */
function displayNotice(msg) {
    if ( typeof msg === 'undefined' ) return;
    var notice = $('#noticeSlider');
    notice.addClass('notice').removeClass('at-top-edge').removeClass('bottom-notice').removeClass('success').removeClass('error').removeClass('hidden');
    notice.children('div.notice-content').html(msg);
    hideErrorSuccessNotice(false);
}

/**
 *
 * Display sliding notice message from bottom of screen
 *
 * @param msg String - notice message to display
 */
function displayBottomNotice(msg) {
    if ( typeof msg === 'undefined' ) return;
    var notice = $('#noticeSlider');
    notice.addClass('bottom-notice').removeClass('at-top-edge').removeClass('notice').removeClass('success').removeClass('error');
    setTimeout(function() {
        notice.removeClass('hidden');
    },100);
    notice.children('div.notice-content').html(msg);
}

/**
 * Hide the sliding message from the top of the screen after brief timeout
 */
function hideErrorSuccessNotice(immediately) {
    if ( !immediately ) {
        setTimeout(function () {
            $('#noticeSlider').addClass('hidden');
        }, 4000);
    } else {
        $('#noticeSlider').addClass('hidden');
    }
}

/**
 * Calculates how many pixels the header (and ad) take up, used to position additional elements correctly at the top of the page
 *
 * @returns {number}
 */
function getHeaderOffset() {

    var body = $('body');
    var topAd = $('#topFixedAd');
    var header = $('#header');

    var headerOffset = 0;

    if ( !body.hasClass('no-ad') ) {
        if ( body.hasClass('hidden-header') ) {
            headerOffset += topAd.height();
        } else {
            headerOffset += topAd.height() + header.height();
        }
    } else {
        headerOffset += header.height();
    }

    if (headerOffset > 0) {
        headerOffset += 10;
    }

    return headerOffset;
}

function loadCss(url) {
    var link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = url;
    document.getElementsByTagName("head")[0].appendChild(link);
}

// get permutive values
var permutiveData = {
    getEnv: function(name) {
        if ('tsr' in window && 'env' in window.tsr) {
            var value = window.tsr.env[name] || null;
            if (typeof value === 'string' && value.length > 0) {
                return value.split('|');
            } else if (typeof value === 'object') {
                return value;
            }
        }
        return null;
    },
    extend: function() {
        for (var i = 1; i < arguments.length; i++) {
            for (var key in arguments[i]) {
                if (arguments[i].hasOwnProperty(key)) {
                    if (typeof arguments[0][key] === 'object' && typeof arguments[i][key] === 'object') {
                        extend(arguments[0][key], arguments[i][key]);
                    } else {
                        arguments[0][key] = arguments[i][key];
                    }
                }
            }
        }
        return arguments[0];
    },
    values: function() {
        var page = {
            page_edu_levels: this.getEnv('tsr_edu_level'),
            page_categories: this.getEnv('tsr_page_category'),
            page_subjects: this.getEnv('tsr_subject'),
            page_uni_colleges: this.getEnv('tsr_uni_college'),
            page_uni_courses: this.getEnv('tsr_uni_course'),
            page_type: (typeof window.THIS_SCRIPT !== 'undefined') ? String(window.THIS_SCRIPT) : null,
            user: {
                logged_in: !!window.USERID,
                is_member: !!(window.USERID || fetch_cookie(window.COOKIE_PREFIX + '_returning'))
            }
        };
        // Add data specified by services
        if ('dmpData' in window) {
            this.extend(page, window.dmpData);
        }
        return page;
    }
};

// Set a default value for experimentId and variationId
window.EXPERIMENTID = '';
window.VARIATIONID = '';

// If an experiment is running, update them to those values
function handleOptimizeExperiments(value, name) {
    window.EXPERIMENTID = name;
    window.VARIATIONID = value;
}

gtag('event', 'optimize.callback', {
    callback: handleOptimizeExperiments
});
