require([
    'jquery',
    'structure/headfunctions'
], function($) {

    $(document).ready(function() {
        $.fn.isInViewport = function(topOffset, bottomOffset) {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight();
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height() - bottomOffset;
            viewportTop += topOffset;
            return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        if (THIS_SCRIPT == 'showthread') {
            startRelatedDiscussionVisibilityCheck();
            startRelatedArticlesForumVisibilityCheck();
        }

    });
        
});

function startRelatedDiscussionVisibilityCheck() {
    var relatedDiscussionWidget = null;

    if ($('#PrimaryRelatedDiscussions').length == 1 && $('#PrimaryRelatedDiscussions').is(':visible')) { // Showthread 2
        relatedDiscussionWidget = $('#PrimaryRelatedDiscussions');
    } else if ($('#WidgetsRelatedDiscussions').length == 1 && $('#WidgetsRelatedDiscussions').is(':visible')) { // Showthread 1
        relatedDiscussionWidget = $('#WidgetsRelatedDiscussions');
    }

    if (relatedDiscussionWidget !== null) {
        window.relatedDiscussionCheck = {
            relatedDiscussionFrameRequested: false,
            relatedDiscussionWidget: relatedDiscussionWidget,
            viewed: false,
        };

        window.relatedDiscussionVisibleCheck = function() {
            extraTopOffset = 65;
            if (typeof (TSR_DY_THIS_SCRIPT) !== 'undefined' && TSR_DY_THIS_SCRIPT == 'showthread2') {
                extraTopOffset += $('#header').outerHeight() + ($('#topFixedAd').is(':visible') ? $('#topFixedAd').outerHeight() : $('#navigationMenu').outerHeight());
            } else {
                extraTopOffset += ($('#header_container').is(':visible') ? $('#user-menu').outerHeight() : 0) + ($('#mobile-ad-floating').length ==1 ? $('#mobile-ad-floating').outerHeight() : 0) + $('#main-menu').outerHeight();
            }
            seeMore = relatedDiscussionCheck.relatedDiscussionWidget.find('div.see-more');
            if (seeMore.length == 1 && seeMore.is(':visible')) {
                extraTopOffset += seeMore.outerHeight();
            }
        
            extraBottomOffset = 90;

            if (relatedDiscussionCheck.relatedDiscussionWidget.isInViewport(extraTopOffset, extraBottomOffset)) {
                relatedDiscussionCheck.viewed = true;
                if ( typeof _gaq !== 'undefined' ) {
                    _gaq.push(['_trackEvent', 'Widgets', 'RelatedDiscussions', 'Viewed', 1, true]);
                }
                ga('send', 'event', 'Widgets', 'RelatedDiscussions', 'Viewed', {
                    nonInteraction: true
                });
            }

            relatedDiscussionCheck.relatedDiscussionFrameRequested = false;
        };

        $(window).on('scroll', function() {
            if (relatedDiscussionCheck.relatedDiscussionFrameRequested || relatedDiscussionCheck.viewed || relatedDiscussionCheck.relatedDiscussionWidget.length == 0) return;
        
            requestAnimationFrame(relatedDiscussionVisibleCheck);
        
            relatedDiscussionCheck.relatedDiscussionFrameRequested = true;
        });

        relatedDiscussionVisibleCheck();
    } else { // no related discussions widget showing on page
        if ( typeof _gaq !== 'undefined' ) {
            _gaq.push(['_trackEvent', 'Widgets', 'RelatedDiscussions', 'Not found', 1, true]);
        }
        ga('send', 'event', 'Widgets', 'RelatedDiscussions', 'Not found', {
            nonInteraction: true
        });
    }
}

function startRelatedArticlesForumVisibilityCheck() {
    var relatedArticlesWidget = null;
    
    if ($('#PrimaryRelatedArticlesForum').length == 1 && $('#PrimaryRelatedArticlesForum').is(':visible')) { // desktop
        relatedArticlesWidget = $('#PrimaryRelatedArticlesForum');
    } else if ($('#SidebarRelatedArticlesForum').length == 1 && $('#SidebarRelatedArticlesForum').is(':visible')) { // mobile
        relatedArticlesWidget = $('#SidebarRelatedArticlesForum');
    }
    
    if (relatedArticlesWidget !== null) {
        window.relatedArticlesCheck = {
            frameRequested: false,
            widget: relatedArticlesWidget,
            viewed: false,
        };

        window.relatedArticlesVisibleCheck = function() {
            extraTopOffset = $('#header').outerHeight() + ($('#topFixedAd').is(':visible') ? $('#topFixedAd').outerHeight() : $('#navigationMenu').outerHeight()) + 100;
            extraBottomOffset = 100;
            
            if ($('#mobileQuickReply').length == 1 && $('#mobileQuickReply').is(':visible')) {
                extraBottomOffset += $('#mobileQuickReply div.dummy-reply').outerHeight() + 50;
            } else if ($('#mobileQuickNavbar').length == 1 && $('#mobileQuickNavbar').is(':visible')) {
                extraBottomOffset += $('#mobileQuickNavbar').outerHeight() + 50;
            }
            
            if (relatedArticlesCheck.widget.isInViewport(extraTopOffset, extraBottomOffset)) {
                relatedArticlesCheck.viewed = true;
                if ( typeof _gaq !== 'undefined' ) {
                    _gaq.push(['_trackEvent', 'Widgets', 'RelatedArticles', 'Viewed', 1, true]);
                }
                ga('send', 'event', 'Widgets', 'RelatedArticles', 'Viewed', {
                    nonInteraction: true
                });
            }

            relatedArticlesCheck.frameRequested = false;
        };

        $(window).on('scroll', function() {
            if (relatedArticlesCheck.frameRequested || relatedArticlesCheck.viewed || relatedArticlesCheck.widget.length == 0) return;
        
            requestAnimationFrame(relatedArticlesVisibleCheck);
        
            relatedArticlesCheck.frameRequested = true;
        });

        relatedArticlesVisibleCheck();
    }
}









