/**
 * Configure RequireJS
 *
 * This file sets up where RequireJS can load files from, and also maps out dependencies that we otherwise have no control over.
 *
 * This is a headsync file, so overhead needs to be kept to a minimum.
 */
(function() {
    var makeConfig = function(settings) {
        var op = {
            // Paths: indicate how module names map to actual files. For most apps, this is just '<appname>/js'
            paths: {
                bloodhound: 'forum/clientscript/gsa-search/typeahead',
                jquery: 'empty:',
                pusher: 'https://js.pusher.com/4.1/pusher',
                apple: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth'
            },
            // Seconds before module timeout
            waitSeconds: 60
        };
        if (!settings.noAppPaths) {
            op.paths.asset = 'asset/js';
            op.paths.forum = 'forum/clientscript';
            op.paths.structure = 'structure/js';
            op.paths.forumhub = 'forumhub/js';
            op.paths.widget = 'widget/js';
            op.paths.dpc = 'dpc/js';
        }

        if (!!settings.baseUrl) op.baseUrl = settings.baseUrl;
        // Bundles: indicate which modules are present in global.js
        // Not used if minification is off. Ignored by minification process
        if (!!settings.minify && !!settings.bundles) op.bundles = settings.bundles;
        // Shim: Allows dependencies to be specified manually. You shoudn't be adding new stuff here, unless you're dealing with an old third-party library
        // Currently always enabled
        if (!settings.noShim) op.shim = {
            'forum/common/functions': [
                'forum/common/init',
                'forum/common/jquery.supermodal',
                'forum/extra/yestoday',
                'forum/lib/notifit',
                'jquery'
            ],
            'forum/extra/ed': [
                'forum/common/init',
                'forum/common/functions',
                'forum/common/jquery.supermodal',
                'forum/common/polyfill',
                'forum/lib/jquery.ui',
                'forum/lib/modernizr',
                'jquery'
            ],
            'forum/gsa-search/clamp': {
                exports: '$clamp'
            },
            'forum/gsa-search/fixed': {
                exports: 'FixedFixed'
            },
            'forum/gsa-search/sticky': {
                deps: ['forum/gsa-search/fixed', 'jquery'],
                exports: 'FixedSticky'
            },
            'forum/gsa-search/typeahead': {
                deps: ['jquery'],
                init: function($) {
                    // Typeahead rather obnoxiously defines itself as 'typeahead.js', which is incompatible with RequireJS (module names are not allowed to have a '.js' extension).
                    // This is a workaround so that define/require calls for typeahead.js can be made as 'forum/gsa-search/typeahead'

                    //+HDK-318 bloodhound was not being loaded and therefore search was not working.
                    // We now have Bloodhound in it's own file and load it here.
                    require.s.contexts._.registry['bloodhound'].factory($);

                    return require.s.contexts._.registry['typeahead.js'].factory($);
                }
            },
            'forum/lib/highcharts': {
                exports: 'Highcharts'
            },
            'forum/lib/jquery.form': ['jquery'],
            'forum/lib/jquery.hoverintent': ['jquery'],
            'forum/lib/jquery.migrate': ['jquery'],
            'forum/lib/jquery.postmessage': ['jquery'],
            'forum/lib/jquery.scrollto': ['jquery'],
            'forum/lib/jquery.showmore': ['jquery'],
            'forum/lib/jquery.slimscroll': ['jquery'],
            'forum/lib/jquery.ui.touchpunch': ['jquery', 'forum/lib/jquery.ui'],
            'forum/lib/jquery.waypoints': ['jquery'],
            'forum/lib/menu-aim': {
                exports: 'menuAim'
            },
            'forum/lib/modernizr': {
                exports: 'Modernizr'
            },
            'structure/lib/sceditor/plugins/plaintext': [
                'structure/lib/sceditor/jquery.sceditor.bbcode.min'
            ],
        };
        // Map: alias a module to another name. We use this for wrapping some third-party code into our own loaders
        // Only used on frontend, not used by minification process
        if (!settings.noMap) op.map = {
            '*': {
                'forum/lib/cookie': 'shim/cookie',
                'forum/lib/notifit': 'shim/notifit',
                'jquery': 'shim/jquery'
            },
            'forum/lib/jquery.migrate': {
                // Because shim/jquery loads in jquery.migrate, we need this mapping so we don't get a circular dependency
                'jquery': 'jquery'
            },
            'shim/cookie': {
                'forum/lib/cookie': 'forum/lib/cookie'
            },
            'shim/jquery': {
                'jquery': 'jquery'
            },
            'shim/notifit': {
                'forum/lib/notifit': 'forum/lib/notifit'
            }
        };
        if (!!settings.extra) {
            for (var i in settings.extra) {
                if (settings.extra.hasOwnProperty(i)) op[i] = settings.extra[i];
            }
        }
        return op;
    };
    // Are we in a browser?
    if (typeof window === 'object') {
        var settings = tsr.data.structure.get('requirejs');
        if (!requirejs || !define || !settings) return;

        // Define configuration for RequireJS
        requirejs.config(makeConfig(settings));

        if (!settings.nomap) {
            // Cookie shim: make the Cookies variable global
            define('shim/cookie', ['forum/lib/cookie'], function(factory) {
                this.Cookies = factory;
                return factory;
            });
            // jQuery shim: load jquery.migrate plugin
            define('shim/jquery', ['jquery', 'forum/lib/jquery.migrate'], function($) {
                return $;
            });
            // notifIt shim: Make notif, notif_dismiss and notif_confirm variables global
            define('shim/notifit', ['forum/lib/notifit'], function(factory) {
                this.notif = factory.notif;
                this.notifit_dismiss = factory.notifit_dismiss;
                this.notif_confirm = factory.notif_confirm;
                return factory;
            });
        }
    } else if (typeof global === 'object' && 'rjsSettings' in global) {
        // Define config for use in Gulp
        global.rjsConfig = makeConfig(global.rjsSettings);
    }
})();
