// Show Ads
if (tsr.data.structure.show_ads) {
    if (tsr.data.structure.quantcast_active) {
        window.quantSegs = [];
    }
    window.dfp = tsr.data.structure.dfp_data;
}

/**
 * Quantcast callback function
 *
 * Invoked via JSONP, and chains into DFP to load the site ads
 */
function qc_results(result) {
    if (typeof dfp === 'undefined') return;
    if (typeof quantSegs === 'undefined') return;

    quantSegs = [];
    for (var i = 0; i < result.segments.length; i++) {
        quantSegs.push(result.segments[i].id);
    }
    $.extend(dfp.targeting, { 'u_qc': quantSegs });
}

function hideInPostAds() {
    if ($('.ad-divider').length > 0) {
        $('.ad-divider').hide();
    }

    if ($('#postsContainer > .advert-block').length > 0) {
        $('#postsContainer > .advert-block').hide();
    }

    $('#inpost-announcements').hide();
}

/*
 * Taken from TSR headinclude.html
 */
window.dfpHooks = {
    _hooks: {},
    _timers: {},
    _start: null,
    get: function(name) {
        var self = this;
        if (Object.keys(this._hooks).indexOf(name) !== -1) {
            return this._hooks[name];
        }
        this._start = this._start || Date.now();
        var hook = $.Deferred().done(function(args) {
            dfpHooks.log(name + ' resolved by ' + args);
        }).progress(function(state) {
            if (state === 'starting' && typeof(dfpHooks._timers[name]) !== undefined) {
                clearTimeout(self._timers[name]);
                self._timers[name] = undefined;
            }
        });
        this._timers[name] = setTimeout(function() {
            hook.resolve('timeout');
        }, tsr.data.structure.get('dfp_timeout') * 1000);
        this._hooks[name] = hook;
        this.log('Testing ' + name);
        return hook;
    },
    toArray: function() {
        var self = this;
        return Object.keys(this._hooks).map(function (key) {
            return self._hooks[key];
        });
    },
    getElapsed: function() {
        return ('      ' + ((this._start? Date.now() - this._start: 0) / 1000.0).toFixed(3)).slice(-6);
    },
    log: function(message) {
        if (IS_DEV || BBURL.indexOf('staging')) {
            console.log('[' + dfpHooks.getElapsed() + '] DFP hooks: ' + message)
        }
    }
};

// Make sure we always have values for these figures
if ( typeof tsr.data.structure.adverts == 'undefined' ) {
    tsr.data.structure.adverts = {};
    tsr.data.structure.adverts.lazyload = 0;
    tsr.data.structure.adverts.sra = 0;
    tsr.data.structure.adverts.scrolling_top_time = 0;
} else {
    if ( typeof tsr.data.structure.adverts.lazyload == 'undefined' ) {
        tsr.data.structure.adverts.lazyload = 0;
    }
    if ( typeof tsr.data.structure.adverts.scrolling_top_time == 'undefined' ) {
        tsr.data.structure.adverts.scrolling_top_time = 0;
    }
    if ( typeof tsr.data.structure.adverts.sra == 'undefined' ) {
        tsr.data.structure.adverts.sra = 0;
    }
}

// Make sure DFP exists on compact pages (register modal etc)
if (typeof dfp == 'undefined') {
    dfp = {};
} else if (typeof dfp.targeting == 'undefined') {
    dfp.targeting = {};
}

var VARIATION_NAME;
if (typeof tsr.data.structure.adverts == 'undefined') {
    tsr.data.structure.adverts = { ias: {iasPubId: null} };
} else if ( typeof tsr.data.structure.adverts.ias == 'undefined' ) {
    tsr.data.structure.adverts.ias = {iasPubId: null};
}

var ads = {
    iasPubId: tsr.data.structure.adverts.ias.iasPubId,
    started: false,
    units: [],
    gptadslots: [],
    toInitialLoad: [],
    toLazyLoad: [],
    lazyLoad: tsr.data.structure.adverts.lazyload,
    lazyLoadListeners: [],
    stack: 'standard',
    otherStack: 'mobile',
    targeting: dfp.targeting,
    spotlightAds: false,
    announcementAds: false,
    scrollingAd: {
        started: false,
        canScroll: false,
        timer: undefined,
        scrollTime: tsr.data.structure.adverts.scrolling_top_time,
        initialOffset: 0
    },
    cmpComplete: false,
    spareAdId: 100,
    autoRefreshingAds: [],
    pageResizePoints: [],
    canInitialLoad: true,
    startAds: function() {
        if (tsr.data.structure.show_ads) {
            if( window.googletag && googletag.apiReady && ads.started === false && ads.cmpComplete === true) {
                googletag.cmd.push(function () {
                    ads.setOptimizeDataVariation();

                    // Fallback for Header Bidder Wrapper
                    if (typeof window.headertag === 'undefined' || window.headertag.apiReady !== true) {
                        window.headertag = googletag;
                    }

                    ads.setStack();
                    ads.setGlobalTargeting();
                    ads.setPermutiveTargeting();
                    ads.setAdUnits();
                });

                ads.setupIAS();
                ads.canInitialLoad = ads.setupAmazon();

                googletag.cmd.push(function () {
                    ads.createAdSlots();
                    ads.displayAds(ads.toInitialLoad);
                    ads.loadAds();
                });

                if (ads.canInitialLoad) {
                    setTimeout(function () {
                        ads.refreshAds(ads.toInitialLoad);
                    }, 250);

                    ads.setupOnLoadCallback();
                    ads.setupReceiveMessage();
                    ads.started = true;
                }

            } else {
                setTimeout(function () {
                    ads.startAds();
                }, 50);
                return false;
            }
        } else {
            hideInPostAds();
        }
    },
    setStack: function() {
        if (RESPONSIVE_ADS) {
            ads.stack = 'responsive';
        } else {
            var mob = isMobile();
            ads.stack = mob ? 'mobile' : 'standard';
            ads.otherStack = mob ? 'standard' : 'mobile';
        }
    },
    setGlobalTargeting: function() {
        var showthread2SEO = ['143', '338', '151', '22', '176', '229', '1530', '117', '264'].includes(FORUM_ID.toString());
        var pageVersion;

        if (THIS_SCRIPT == 'showthread') {
            if (showthread2SEO) {
                pageVersion = 'thread_supernova_seo';
            } else {
                pageVersion = 'thread_supernova';
            }
        } else {
            pageVersion = 'other_supernova';
        }

        var topics = [];
        if ((window.TSRDataLayer.pageData.topics || []).length > 0) {
            topics = window.TSRDataLayer.pageData.topics;
        }

        var oldTopics = [];
        if (this.getEnv('tsr_uni_college')) {
            oldTopics = oldTopics.concat(this.getEnv('tsr_uni_college'));
        }
        if (this.getEnv('tsr_edu_level')) {
            oldTopics = oldTopics.concat(this.getEnv('tsr_edu_level'));
        }
        if (this.getEnv('tsr_uni_college')) {
            oldTopics = oldTopics.concat(this.getEnv('tsr_uni_college'));
        }
        if (this.getEnv('tsr_subject')) {
            oldTopics = oldTopics.concat(this.getEnv('tsr_subject'));
        }
        if (this.getEnv('tsr_uni_course')) {
            oldTopics = oldTopics.concat(this.getEnv('tsr_uni_course'));
        }

        $.extend(ads.targeting, {
            'u_b_f': getUserBehave(),
            'kuid': getKruxData('user'),
            'ksg': getKruxData('segs'),
            'fisher': parseInt(fisherBucket),
            'page_version': pageVersion,

            // old permutive stuff
            'old_topics': oldTopics,
            // api.categorisation output
            'topics': topics,

            // api.profile output
            'u_country': window.TSRDataLayer.userData.country || '',
            'u_post_code': window.TSRDataLayer.userData.post_code || '',
            'u_career_phase': window.TSRDataLayer.userData.career_phase || '',
            'u_qualifications': window.TSRDataLayer.userData.qualifications || [],
            'u_year_group': window.TSRDataLayer.userData.year_group || 0,
            'u_cur_subjects': window.TSRDataLayer.userData.current_subjects || [],
            'u_fut_subjects': window.TSRDataLayer.userData.future_subjects || [],
            'u_cur_learning_prov': window.TSRDataLayer.userData.current_learning_providers || [],
            'u_fut_learning_prov': window.TSRDataLayer.userData.future_learning_providers || [],
            'u_uni_start_year': window.TSRDataLayer.userData.intended_university_start_year || 0
        });

        if (THIS_SCRIPT == 'showthread') {
            currentPage = '1';
            if ($('a.page-link.active').length > 0) {
                currentPage = $('a.page-link.active').data('page-number');
            }

            $.extend(ads.targeting, {
                'p_t_id': THREAD_ID,
                'p_t_p': currentPage
            });
        }

        for (var target in ads.targeting) {
            googletag.pubads().setTargeting(target, this.targeting[target].toString());
        }
    },
    updateGlobalTargeting: function(updatedTargeting) {
        if (window.googletag && typeof window.googletag.pubads !== 'undefined') {
            for (var target in updatedTargeting) {
                tsr.data.structure.dfp_data.targeting[target] = updatedTargeting[target];
                googletag.pubads().setTargeting(target, updatedTargeting[target].toString());
            }
            window.dfp = tsr.data.structure.dfp_data;
        }
    },
    setPermutiveTargeting: function() {
        googletag.cmd.push(function () {
            if (googletag.pubads().getTargeting('permutive').length === 0) {
                try {
                    var kvs = window.localStorage.getItem('_pdfps');
                    googletag.pubads().setTargeting('permutive', kvs ? JSON.parse(kvs) : []);
                } catch (e) {}
            }
        });
    },
    generateNewId: function(element) {
        var id = 'gpt-ad-' + ads.spareAdId;
        element.attr('id', id);
        ads.spareAdId++;
        return id;
    },
    /* Grab information from HTML on site */
    setAdUnits: function() {
        $('.advert-' + ads.stack).each(function () {

            if (isMobile() && $(this).data('adunit') == 'tsr-page-takeover-all-site') return;

            // Generate new id (starting from 100 for safety) and add to div
            var id = $(this).attr('id');
            if (id === undefined || id in ads.units) {
                id = ads.generateNewId($(this));
            }
            // Convert dimensions into object if not already
            var dimensions = $(this).data('dimensions');
            if ( typeof $(this).data('dimensions') !== 'object' ) {
                dimensions = dimensions.split('x');
                for ( var dim in dimensions ) {
                    dimensions[dim] = parseInt(dimensions[dim]);
                }
                $(this).data('dimensions', [dimensions]);
            }

            if (!ads.isRestricted.call(this)) {
                ads.units[id] = $(this).data();
            } else {
                var ad = $('#' + id);
                if (ad.data('adunit') === 'tsr-sponsored-forums-all-site' || ad.data('adunit') === 'tsr-sponsored-forums-all-site-fluid') {
                    ad.hide();
                    ad.parent().hide();
                    ad.closest('.has-sponsor').removeClass('has-sponsor');
                }
           }
        });
    },
    /**
     * Determine if an ad unit is restricted due to its targeting constraints
     *
     * @this    HTMLElement     Advert element
     *
     * @returns {boolean}       True if the ad should be restricted from display, false if display is allowed
     */
    isRestricted: function() {
        var restrictions = $(this).data('restricttargeting');

        if (typeof restrictions !== 'object' || restrictions.length === 0) {
            // No restrictions defined for this ad
            return false;
        }

        for (var i in restrictions) {
            var passing = true;
            for (var j in restrictions[i]) {
                var rule = restrictions[i][j];
                if (!(rule.key in ads.targeting)) {
                    // Required targeting parameter missing - rule failed
                    passing = false;
                    break;
                }

                var isIn = false;
                for (var k in rule.values) {
                    if (ads.checkDFPTargetingRule(rule.values[k], ads.targeting[rule.key])) {
                        isIn = true;
                        break;
                    }
                }
                if (rule.rel === 'is' ? !isIn : isIn) {
                    // Rule requirements not met by targeting parameter value - rule failed
                    passing = false;
                    break;
                }
            }
            if (passing) {
                // If this rule fully passes, then the ad is unrestricted
                return false;
            }
        }

        // If no available rule passes, restrict the ad from displaying
        return true;
    },
    /**
     * Check if a targeting rule matches the given value
     *
     * @param   {string}    needle      Search string
     * @param   {string}    haystack    Parameter value
     *
     * @returns {boolean}
     */
    checkDFPTargetingRule: function(needle, haystack) {
        if (needle.substr(0, 1) === '~') {
            if (needle.substr(-1) === '*') {
                if (haystack.match(new RegExp('\\b'+ needle.substring(1, needle.length - 1)))) return true;
            } else {
                if (haystack.match(new RegExp('\\b'+ needle.substr(1) +'\\b'))) return true;
            }
        } else if (needle.substr(-1) === '*') {
            if (haystack.indexOf(needle.substr(0, needle.length - 1)) === 0) return true;
        } else {
            if (needle === haystack) return true;
        }
        return false;
    },
    setupIAS: function() {
        if ( window.__iasPET && window.__iasPET.sessionId && ads.iasPubId ) {
            __iasPET.pubId = ads.iasPubId; // Set IAS publisher ID before pushing to the queue
            for ( var unit in ads.units ) {
                __iasPET.queue.push({
                    adSlots: {
                        adSlotId: unit,
                        size: ads.units[unit].dimensions,
                        adUnitPath: '/' + dfp.publisherId + '/' + ads.units[unit].adunit
                    },
                    dataHandler: window.iasDataHandler
                });
            }
        }
    },
    setupAmazon: function() {
        if (!('apstag' in window)) return true;

        var slots = [];
        var sizes;
        for (var unit in ads.units) {
            sizes = ads.units[unit].dimensions;
            // Skip ads with no valid dimensions or fluid dimensions
            if (typeof sizes !== 'object' || sizes.indexOf('fluid') !== -1) continue;
            // Force a 2D array for dimensions
            if (typeof sizes[0] !== 'object') {
                sizes = [sizes];
            }

            slots.push({
                slotID: unit,
                slotName: dfp.publisherId + '/' + ads.units[unit].adunit,
                sizes: sizes
            });
        }

        if (slots.length > 0) {
            window.apstag.fetchBids({
                slots: slots,
                timeout: 2e3
            }, function() {
                googletag.cmd.push(function() {
                    window.apstag.setDisplayBids();
                    ads.canInitialLoad = true;

                    ads.refreshAds(ads.toInitialLoad);
                    ads.setupOnLoadCallback();
                    ads.setupReceiveMessage();
                    ads.started = true;
                });
            });
        }

        return false;
    },
    createAdSlots: function() {

        for ( var unit in ads.units ) {
            // Create new ad slot
            ads.gptadslots[unit] = googletag.defineSlot('/' + dfp.publisherId + '/' + ads.units[unit].adunit, ads.units[unit].dimensions, unit);
            ads.gptadslots.length++;

            var adResponsiveVisible = null;
            var pageWidth = parseInt(window.innerWidth);
            if ( ads.units[unit].responsive ) {
                // Need to check correct format of responsive
                ads.gptadslots[unit].defineSizeMapping(ads.units[unit].responsive);

                for (var mapping in ads.units[unit].responsive) {
                    var browserSize = ads.units[unit].responsive[mapping][0];
                    var unitSizes = ads.units[unit].responsive[mapping][1];

                    // If this breakpoint matches the current browser width, determine if the ad has renderable unit sizes at this page width
                    if (pageWidth >= browserSize[0] && adResponsiveVisible === null) {
                        adResponsiveVisible = unitSizes.length > 0;
                    }

                    if ( typeof ads.pageResizePoints[browserSize[0]] == 'undefined' && browserSize[0] > 0 ) {
                        ads.pageResizePoints[browserSize[0]] = [];
                    }
                    if ( browserSize[0] > 0 ) {
                        ads.pageResizePoints[browserSize[0]].push({
                            id: unit,
                            slot: ads.gptadslots[unit],
                            unit: ads.units[unit]
                        });
                    }
                }
            }

            // Setting up variation targeting for experiments
            var variation = (ads.units[unit].variation) ? ads.units[unit].variation : 'default';
            ads.gptadslots[unit].setTargeting('variation', variation);

            // Setting targeting for IX
            var positionname = (ads.units[unit].positionname) ? ads.units[unit].positionname : THIS_SCRIPT;
            ads.gptadslots[unit].setTargeting('ix-adslot', positionname);

            if ( window.__iasPET && window.__iasPET.sessionId ) {
                __iasPET.setTargetingForGPT();
            }

            // Add the pubads service to unit
            ads.gptadslots[unit].addService(googletag.pubads());

            if (adResponsiveVisible === null || adResponsiveVisible === true) {
                if (!ads.lazyLoad || !ads.units[unit].delay) {
                    // (not using array.push() as need to maintain key as unit name)
                    ads.toInitialLoad[unit] = ads.gptadslots[unit];
                    ads.toInitialLoad.length++;
                } else { // Lazy loading is switched off, and unit set for lazy load
                    // Set up new lazy load group (if not already set-up
                    if (typeof ads.toLazyLoad[ads.units[unit].delaygroup] == 'undefined') {
                        ads.toLazyLoad[ads.units[unit].delaygroup] = [];
                        ads.toLazyLoad[ads.units[unit].delaygroup]['units'] = [];
                        ads.toLazyLoad.length++;
                    }

                    // Add unit to lazy load group (not using array.push() as need to maintain key as unit name)
                    ads.toLazyLoad[ads.units[unit].delaygroup]['units'][unit] = ads.gptadslots[unit];
                    ads.toLazyLoad[ads.units[unit].delaygroup].length++;

                    // Work out the 'trigger unit' for the group
                    if (
                        typeof ads.toLazyLoad[ads.units[unit].delaygroup]['triggerunit'] == 'undefined' ||
                        $('#' + unit).offset().top < $('#' + ads.toLazyLoad[ads.units[unit].delaygroup]['triggerunit']).offset().top
                    ) {
                        ads.toLazyLoad[ads.units[unit].delaygroup]['triggerunit'] = unit;
                        ads.toLazyLoad[ads.units[unit].delaygroup]['triggeroffset'] = ads.units[unit].delay;
                    }
                }
            }
        }

        // Are we already past the lazy load 'trigger point'
        var scrollTop = $(window).scrollTop();
        for ( var lazygroup in ads.toLazyLoad ) {

            var triggerUnit = $('#' + ads.toLazyLoad[lazygroup]['triggerunit']);
            var delayTop = triggerUnit.offset().top - ads.toLazyLoad[lazygroup]['triggeroffset'];
            var delayBottom = (triggerUnit.offset().top + triggerUnit.height()) + ads.toLazyLoad[lazygroup]['triggeroffset'];
            // Effectively 'removing' upward lazy load from spotlight ads as they reposition down the page as the user scrolls
            // By setting the delayBottom to the height of the doc the scroll position will always match so upward lazy loading is eliminated
            if ( lazygroup == 'spotlight' ) {
                delayBottom = $(document).height();
            }
            ads.toLazyLoad[lazygroup]['delaytop'] = delayTop;
            ads.toLazyLoad[lazygroup]['delaybottom'] = delayBottom;

            if ( scrollTop >= delayTop && scrollTop <= delayBottom ) {
                // Add to initial load
                for ( var lazyUnit in ads.toLazyLoad[lazygroup]['units'] ) {
                    // (not using array.push() as need to maintain key as unit name)
                    ads.toInitialLoad[lazyUnit] = ads.toLazyLoad[lazygroup]['units'][lazyUnit];
                    ads.toInitialLoad.length++;
                }

                // Remove from lazy load
                delete ads.toLazyLoad[lazygroup];
                ads.toLazyLoad.length--;
            }
        }
    },
    displayAds: function(units) {
        for ( var unit in units ) {
            headertag.display(unit);
        }
    },
    loadAds: function() {
        googletag.pubads().enableSingleRequest();
        googletag.pubads().disableInitialLoad();
        googletag.pubads().enableAsyncRendering();
        googletag.enableServices();
    },
    refreshAds: function(adsToRefresh) {

        ads.currentPageWidth = parseInt(window.innerWidth);

        if(ads.cmpComplete === true && window.googletag && googletag.pubadsReady) {

            // Array passed to refresh() needs to be zero-indexed
            var adsArr = [];
            for (var ad in adsToRefresh) {
                adsArr.push(adsToRefresh[ad]);
            }

            googletag.cmd.push(function () {
                headertag.pubads().refresh(adsArr);
            });
        } else {
            setTimeout(function () {
                ads.refreshAds(adsToRefresh);
            }, 50);
            return false;
        }
    },
    setupLazyLoad: function() {
        // SET-UP SCROLL HANDLERs FOR DELAYED ITEMS
        if ( ads.lazyLoad && ads.toLazyLoad.length > 0 ) {
            window['adListeners'] = [];
            for ( var adgroup in ads.toLazyLoad ) {

                var delayTop = ads.toLazyLoad[adgroup]['delaytop'];
                var delayBottom = ads.toLazyLoad[adgroup]['delaybottom'];

                var func = "return function " + adgroup + "() { " +
                    "   if (window.scrollY >= " + delayTop + " && window.scrollY <= " + delayBottom + " ) {  " +
                    "       googletag.cmd.push(function () { " +
                    "           ads.displayAds(ads.toLazyLoad['" + adgroup + "']['units']);" +
                    "       });" +
                    "       ads.refreshAds(ads.toLazyLoad['" + adgroup + "']['units']);" +
                    "       window.removeEventListener('scroll', ads.lazyLoadListeners['" + adgroup + "']);" +
                    "   }" +
                    "}";

                ads.lazyLoadListeners[adgroup] = Function(func)();
                window.addEventListener('scroll', ads.lazyLoadListeners[adgroup]);
            }
        }
    },
    checkInitialPageAds: function() {
        currentPageWidth = parseInt(window.innerWidth);
        for (var pageWidth in ads.pageResizePoints) {
            // if the breakpoint is greater then the page width, check if we need to hide any ads
            if (pageWidth >= currentPageWidth) {
                // Check all ads affected by this breakpoint
                for (var unit in ads.pageResizePoints[pageWidth]) {
                    var ad = ads.pageResizePoints[pageWidth][unit];

                    // Determine the breakpoint in the ad's responsive array that now applies
                    for (var mapping in ad.unit.responsive) {
                        var browserSize = ad.unit.responsive[mapping][0];
                        var unitSizes = ad.unit.responsive[mapping][1];

                        if (currentPageWidth < browserSize[0]) continue;

                        // If this ad has no viewable sizes at this page width, remove it
                        if (unitSizes.length == 0) {
                            $('#'+ ad.id).empty().hide();
                            if ($('#'+ ad.id).parent().hasClass('advert')) {
                                $('#'+ ad.id).parent().hide();
                            }
                        }

                        // We've found the correct mapping and do not need to check any subsequent ones
                        break;
                    }
                }
            }
        }
    },
    setupPageResizePoints: function() {
        ads.currentPageWidth = parseInt(window.innerWidth);
        window.addEventListener('resize', function() {
            var newWidth = parseInt(window.innerWidth);
            if (newWidth === ads.currentPageWidth) return;

            var adsToRefresh = [];
            for (var pageWidth in ads.pageResizePoints) {
                // If the page width has increased, check if the new width is greater than the breakpoint
                // If page width has decreased, check if new width is less than the breakpoint
                if (pageWidth > ads.currentPageWidth ? newWidth >= pageWidth : newWidth < pageWidth) {
                    // Check all ads affected by this breakpoint
                    for (var unit in ads.pageResizePoints[pageWidth]) {
                        var ad = ads.pageResizePoints[pageWidth][unit];

                        // Determine the breakpoint in the ad's responsive array that now applies

                        for (var mapping in ad.unit.responsive) {
                            var browserSize = ad.unit.responsive[mapping][0];
                            var unitSizes = ad.unit.responsive[mapping][1];

                            if (newWidth < browserSize[0]) continue;

                            // If this ad has viewable sizes at this page width, refresh it, otherwise remove it
                            if (unitSizes.length > 0) {
                                adsToRefresh.push(ad.slot);
                            } else {
                                adObj = $('#'+ ad.id);
                                adObj.empty().hide();
                                adObjParent = adObj.parent();
                                if (adObjParent.hasClass('advert')) {
                                    adObjParent.hide();
                                }
                            }

                            // We've found the correct mapping and do not need to check any subsequent ones
                            break;
                        }
                    }
                }
            }
            if (adsToRefresh.length > 0) {
                ads.refreshAds(adsToRefresh);
            }
            ads.currentPageWidth = newWidth;
        });
    },
    setupOnLoadCallback: function () {

        var num_ads = ads.toInitialLoad.length;
        var ad_counter = 0;

        // Sticky bottom showthread mobile
        if ( $('.stickyad-bg').length != 0 ) {
            $('.stickyad-bg').removeClass("stickyad-bg");
        }

        googletag.pubads().addEventListener('slotRenderEnded', function (event) {
            var adid = event.slot.getSlotId().getDomId();
            var a = $('#' + adid);

            ad_counter++;

            if ( ad_counter == num_ads ) {
                setTimeout(function(){
                    ads.allAdsLoaded();
                }, 50);
            }

            console.log('AD RETURNED FROM DFP: ' + adid);

            if (event.isEmpty) {
                if ( a.hasClass('hpu') ) {
                    a.slideUp(1000);
                } else {
                    a.hide();
                    if (a.parent().hasClass('ad-holder')) {
                        a.parent().parent().hide();
                    }
                    if ( a.hasClass('acmt') || a.hasClass('acmtmb') ) {
                        a.parent().addClass('hidden');
                    }
                    if ( a.parent().attr('id')=='page-ad-top' ) {
                        a.parent().hide();
                        a.parent().parent().find('#ad-underlay').hide();
                        a.parent().parent().hide();
                    }
                    if (a.data('adunit') === 'tsr-sponsored-forums-all-site' || a.data('adunit') === 'tsr-sponsored-forums-all-site-fluid') {
                        a.hide();
                        a.parent().hide();
                        a.closest('.has-sponsor').removeClass('has-sponsor');
                    }

                    if ( a.hasClass('acmtfluid') ) {
                        $('#inpost-announcements').slideUp();
                    }

                    if ( a.data('adunit') === 'tsr-alert-unit-mobile' ) {
                        require([
                            'forumhub/pages/showthread',
                        ], function () {
                            if ($('#quickReplyBarContainer').length > 0) {
                                window.activateQRBar();
                            }
                        });
                    }
                }
                return;
            }

            if (!a.hasClass('display-none')) {
                if (a.parent().hasClass('advert')) {
                    a.parent().show();
                }

                // Ad Unit Refresh
                const refreshRate = tsr.data.structure.adverts.refresh_rate;
                const adShouldRefresh = tsr.data.structure.adverts.refreshing_ads.includes(a.data('adunit'));
                if ( refreshRate && adShouldRefresh && typeof ads.autoRefreshingAds[adid] == 'undefined' ) {
                    ads.autoRefreshingAds[adid] = refreshRate;
                    setInterval(function () {
                        ads.refreshAds([ads.gptadslots[adid]]);
                    }, (refreshRate * 1000));
                }

                // Resize ad block to size of iframe returned
                var newHeight = (a.find('div iframe').last().height());
                var newWidth = (a.find('div iframe').last().width());
                // Do not resize fluid ads
                if (!a.hasClass('fluid') && !a.hasClass('spot') && !a.hasClass('respspot') && !a.hasClass('fsfluid') && !a.hasClass('sticky-bottom') && !a.hasClass('acmtfluid')) {
                    a.animate({ height: newHeight, width: newWidth }, 250);
                }

                // Hide spotlights or announcements if no ad loaded
                if ( a.hasClass('spot') || a.hasClass('respspot') || a.hasClass('acmt') || a.hasClass('acmtmb') || a.hasClass('acmtfluid') ) {
                    if ( a.find('iframe').length == 0 ) {
                        a.hide();
                        if ( a.hasClass('acmtfluid') ) {
                            $('#inpost-announcements').slideUp();
                        }
                    }

                    if (a.hasClass('spot') || a.hasClass('respspot')) {
                        ads.spotlightAds = true;
                    } else {
                        ads.announcementAds = true;
                        a.show();
                        a.parent().removeClass('hidden');
                    }
                }

                if ( a.parent().attr('id')=='top_leaderboard_container' ) {
                    a.css("visibility", "visible");
                }

                if ( a.parent().attr('id')=='page-ad-top' ) {
                    a.show();
                    a.parent().show();
                    a.parent().animate({height: (newHeight + 5)}, 250);
                    a.parent().parent().show();
                    a.parent().parent().find('#ad-underlay').animate({height: newHeight + 30}, 250);
                    a.css({height: newHeight, width: newWidth});

                    // Delay to allow unit to re-size before showing
                    setTimeout(function(){
                        a.css({visibility: "visible"});
                        $('#ad-underlay').show();
                    }, 800);
                }

                if (a.data('adunit') === 'tsr-sponsored-forums-all-site') {
                    $('#announcements').hide();
                }

                if ( a.parent().parent().hasClass('post-number-1') ) {
                    a.css({height: newHeight, width: newWidth});
                }

                if ( a.parent().attr('id') == 'topFixedAd' ) {
                    a.show();
                }

                // Sticky bottom showthread mobile
                if ( a.hasClass('sticky-bottom-showthread-mobile') ) {
                    if ( a.find('iframe').length != 0 ) {
                        a.addClass('stickyad-bg');
                    }
                }
            }
        });

        googletag.pubads().addEventListener('slotOnload', function(event) {
            var adInfo = event.slot.getResponseInformation();
            if (adInfo != null) {
                window.TSRDataLayer = window.TSRDataLayer || [];
                window.TSRDataLayer.push({
                    event: {
                        eventType: 'advert_impression',
                        adUnitPath: event.slot.getAdUnitPath(),
                        advertiserId: adInfo.advertiserId,
                        campaignId: adInfo.campaignId,
                        creativeId: adInfo.creativeId,
                        lineItemId: adInfo.lineItemId,
                    }
                });
            }
        });

        googletag.pubads().addEventListener('impressionViewable', function (event) {
            var adInfo = event.slot.getResponseInformation();
            if (adInfo != null) {
                window.TSRDataLayer = window.TSRDataLayer || [];
                window.TSRDataLayer.push({
                    event: {
                        eventType: 'advert_viewable',
                        adUnitPath: event.slot.getAdUnitPath(),
                        advertiserId: adInfo.advertiserId,
                        campaignId: adInfo.campaignId,
                        creativeId: adInfo.creativeId,
                        lineItemId: adInfo.lineItemId,
                    }
                });
            }
        });
    },
    /**
     * Add an event listener for valid messages received from ad iframes
     */
    setupReceiveMessage: function() {
        addEventListener('message', function(e) {
            if (
                !(e.origin === 'http://www.googletagservices.com' ||
                  e.origin === 'https://www.googletagservices.com' ||
                  e.origin.match(/(^|^[^:]+:\/\/|[^\.]+\.)safeframe\.googlesyndication\.com/g) ||
                  e.origin === 'http://tpc.googlesyndication.com' ||
                  e.origin === 'https://tpc.googlesyndication.com' ||
                  e.origin === location.origin) ||
                  typeof e.data !== 'object' ||
                  typeof e.data.unit !== 'string' ||
                  !e.data.unit.match(/^tsr-[\w\d-]+$/)
            ) {
                return;
            }

            var el = $('.advert-block').filter('[data-adunit="'+ e.data.unit +'"]')[0];
            if (el === null) return;

            switch (e.data.unit) {
                case 'tsr-page-takeover-all-site':
                    if (typeof e.data.payload === 'string' && e.data.payload.length > 0) {
                        if (THIS_SCRIPT == 'showthread') {
                            $pageTakeoverRequire = 'structure/pageTakeover';
                        } else {
                            $pageTakeoverRequire = 'forum/common/elements.pageTakeover';
                        }
                        require([$pageTakeoverRequire], function(pageTakeover) {
                            pageTakeover('<div>'+ e.data.payload +'</div>');
                        });
                    }
                    break;
                case 'tsr-sticky-bottom-all-site':
                    if (typeof e.data.cmd !== 'string') return;
                    switch (e.data.cmd) {
                        case 'clickback':
                            document.body.addEventListener('click', function() {
                                e.source.postMessage('clickback', '*');
                            }, { once: true });
                            document.body.addEventListener('touchstart', function() {
                                e.source.postMessage('clickback', '*');
                            }, { once: true });
                            break;
                        case 'close':
                            el.style.display = 'none';
                            break;
                        case 'resize':
                            if (!!e.data.height) {
                                el.style.height = e.data.height + 'px';
                                el.querySelector('iframe').height = e.data.height + 'px';
                            }
                            if (!!e.data.width) {
                                el.style.width = e.data.width + 'px';
                                el.querySelector('iframe').width = e.data.width + 'px';
                            }
                            break;
                    }
                    break;
                case 'sticky-bottom-showthread-mobile':
                    if (typeof e.data.cmd !== 'string') return;
                    switch (e.data.cmd) {
                        case 'clickback':
                            document.body.addEventListener('click', function() {
                                e.source.postMessage('clickback', '*');
                            }, { once: true });
                            document.body.addEventListener('touchstart', function() {
                                e.source.postMessage('clickback', '*');
                            }, { once: true });
                            break;
                        case 'close':
                            el.style.display = 'none';
                            break;
                        case 'resize':
                            if (!!e.data.height) {
                                el.style.height = e.data.height + 'px';
                                el.querySelector('iframe').height = e.data.height + 'px';
                            }
                            if (!!e.data.width) {
                                el.style.width = e.data.width + 'px';
                                el.querySelector('iframe').width = e.data.width + 'px';
                            }
                            break;
                    }
                    break;
            }
        });
    },
    allAdsLoaded: function() {
        sendTimingData('ads');

        // Create on-scroll listeners for lazy loading now all ads have come back
        ads.setupLazyLoad();

        ads.setupPageResizePoints();
        ads.checkInitialPageAds();

        // Only check this if spotlight ads loaded initially and via this method (ignore if loaded via minnie.php)
        if ( !ads.spotlightAds && !ads.toLazyLoad.spotlight ) {
            // Needs to be removed otherwise page scrolls with empty space
            $("#ad_sponsored_placeholder").slideUp(1000).remove();
        }
        // Only check this if announcements ads loaded initially and via this method (ignore if loaded via minnie.php)
        if ( ads.announcementAds ) {
            if ($("#announcements").height() < 35) {
                $("#announcements").slideUp(1000);
            }
        }

        // hide bottom border
        $("#announcements .advert-row").not('.hidden').removeClass('last');
        $("#announcements .advert-row").not('.hidden').last().addClass('last');

        $("#announcements .advert-mobile, #announcements .advert-standard").width("100%");
        $("#announcements .advert-mobile div, #announcements .advert-standard div").width("100%");
        $("#announcements .advert-mobile div iframe, #announcements .advert-standard div iframe").width("100%");

        $("#announcements .announcement_text a").each(function(){
            if ( $(this).html().length < 45 ) {
                $(this).css('lineHeight', '35px');
            }
        });
    },
    clearUnits: function() {
        ads.units = [];
        ads.gptadslots = [];
        ads.toInitialLoad = [];
        ads.toLazyLoad = [];
        ads.pageResizePoints = [];
        ads.autoRefreshingAds = [];
        for ( var lazyLoadFunction in ads.lazyLoadListeners ) {
            window.removeEventListener('scroll', ads.lazyLoadListeners[lazyLoadFunction]);
        }
    },
    restartPage: function() {
        if(window.googletag && googletag.destroySlots && tsr.data.structure.show_ads) {
            var slotsToDestroy = [];
            for (var slot in ads.units) {
                delete(ads.units[slot]);
            }
            for (var slot in ads.gptadslots) {
                slotsToDestroy.push(ads.gptadslots[slot]);
                delete(ads.gptadslots[slot]);
            }
            for (var slot in ads.toInitialLoad) {
                delete(ads.toInitialLoad[slot]);
            }
            for (var ad in ads.toLazyLoad) {
                for (var slot in ads.toLazyLoad[ad]['units']) {
                    delete(ads.toLazyLoad[ad]['units'][slot]);
                }
            }
            for (var size in ads.pageResizePoints) {
                for (var i=0; i<ads.pageResizePoints[size].length; i++) {
                    delete(ads.pageResizePoints[size][i]['slot']);
                }
            }
            googletag.destroySlots(slotsToDestroy);
            var slotLength = slotsToDestroy.length;
            for (var i=0; i<slotLength; i++) {
                delete(slotsToDestroy[i]);
            }
            slotsToDestroy = null;
            ads.clearUnits();
            ads.started = false;
            ads.setStack();

            $('.advert-' + ads.stack).each(function () {
                var a = $(this);
                // Divs need new ids otherwise DFP will fail
                ads.generateNewId(a);

                // Show all ad blocks again (except top header ones - if they're hidden at this point it's for a reason)
                if ( a.parent().attr('id') != 'topFixedAd' ) {
                    a.show();
                }
            });

            googletag.cmd.push(function () {
                ads.setOptimizeDataVariation();
                ads.setAdUnits();
                ads.createAdSlots();
            });

            ads.refreshAds(ads.toInitialLoad);
            ads.setupOnLoadCallback();
        } else if (!tsr.data.structure.show_ads) {
            hideInPostAds();
        }
    },
    resizeScreen: function() {
        // Get current stack
        var currentStack = ads.stack;
        // Reset stack type
        ads.setStack();

        // Has it changed?
        if ( currentStack != ads.stack ) {
            ads.clearUnits();

            // Hide current stack
            $('.advert-' + currentStack).each(function(){
                $(this).hide();
                if ( $(this).hasClass('acmt') || $(this).hasClass('acmtmb') ) {
                    $(this).parent().addClass('hidden');
                    $(this).parents('.advert-row').css('borderBottom', 'none');
                }
            });
            // Show new stack
            $('.advert-' + ads.stack).each(function(){
                $(this).show();
                if ( $(this).hasClass('acmt') || $(this).hasClass('acmtmb') ) {
                    $(this).parent().removeClass('hidden');
                }
            });

            $("#announcements .advert-row").not('.hidden').removeClass('last');
            $("#announcements .advert-row").not('.hidden').last().addClass('last');
            $("#announcements .advert-row").not('.hidden').not('.last').css('borderBottom', '1px dotted #ccc');

            googletag.cmd.push(function () {
                ads.setAdUnits();
                ads.createAdSlots();
            });

            ads.refreshAds(ads.toInitialLoad);
        }
    },
    setOptimizeDataVariation: function() {
        $('.advert-block').attr('data-variation', 'default');
    },
    getEnv: function(name) {
        if ('tsr' in window && 'env' in window.tsr) {
            var value = window.tsr.env[name] || null;
            if (typeof value === 'string' && value.length > 0) {
                return value.split('|');
            } else if (typeof value === 'object') {
                return value;
            }
        }
        return null;
    }
};


$(document).ready(function () {

    if ( typeof DFP_HOOKS !== 'undefined') {
        /* Register specific hooks if not already registered */
        $.each(DFP_HOOKS, function (i, e) {
            dfpHooks.get(e);
        });
    }

    $.when.apply(null, dfpHooks.toArray()).done(function() {
        ads.startAds();
        $(window).resize(ads.resizeScreen);

        $('.advert-block.advert-' + ads.stack + ':not(:empty)').show();
        $('.advert-block.advert-' + ads.otherStack + ':not(.advert-'+ads.stack+')').hide();
        // Hide second border on announcements from the mobile / desktop ad that's not displayed
        $('#announcements .advert-block.advert-' + ads.otherStack).parents('.advert-row').css('borderBottom', 'none');
        $('#announcements .advert-block.advert-' + ads.otherStack).parent().addClass('hidden');
    });
});
